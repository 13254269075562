
// Libraries
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlus } from '@fortawesome/pro-regular-svg-icons'
import Classnames from 'classnames'
import debounce from 'debounce-promise'

// Services
import BroadcastsService from '../services/broadcasts'

// Context
import NotificationContext from '../contexts/notification'

// Components
import Button from './button'
import InputFactory from './inputFactory'

export default class BroadcastRecipientManager extends React.Component {
	constructor(props) {
		super(props);

		const wait = 1000; // milliseconds
		const loadOptions = (inputValue, type) => this.loadOptions(inputValue, type);
		this.debouncedLoadOptions = debounce(loadOptions, wait, {
			leading: true
		});
	}

	static contextType = NotificationContext

	render() {
		const {values, inputChange, inputAdd, inputRemove} = this.props

		let classes = Classnames([
			'input-wrap',
			'input-wrap--broadcastRecipients',
		])

		return <div className={classes}>
			<div className='selects-wrap'>
				{values?.length
					? values.map((value, key) => {
						return <div className='selects-wrap__select'>
							<InputFactory
								type="select"
								name={key}
								scope="recipients"
								value={value?.value ? value : ''}
								noOptionsMessage={() => "Start typing to search ..."}
								loadOptions={(inputValue) => this.debouncedLoadOptions(inputValue, value.type)}
								onChange={inputChange}
							/>
							<Button type='button' iconOnly danger onClick={() => inputRemove(key)}>
								<FontAwesomeIcon icon={faTimesCircle} /><span className='sr-only'> Remove</span>
							</Button>
						</div>
					})
					: <p>Click a button below to start ...</p>
				}
			</div>
			<div className='buttons-wrap'>
				<Button type='button' onClick={() => inputAdd('event')}>
					<FontAwesomeIcon icon={faPlus} /> Event
				</Button>
				<Button type='button' onClick={() => inputAdd('user')}>
					<FontAwesomeIcon icon={faPlus} /> User
				</Button>
				<Button type='button' onClick={() => inputAdd('tag')}>
					<FontAwesomeIcon icon={faPlus} /> Tag
				</Button>
				<Button type='button' onClick={() => inputAdd('list')}>
					<FontAwesomeIcon icon={faPlus} /> List
				</Button>
			</div>
		</div>
	}

	loadOptions = (inputValue, type) => {
		const {addNotification} = this.context

		if (!inputValue) {
			return []
		}
		else if (inputValue.length < 3) {
			return []
		}

		const broadcastsService = new BroadcastsService()
		return broadcastsService.getRecipientOptions(inputValue, type)
			.then((response) => {
				if (response.success) {
					return response.data
				}
				else {
					addNotification('Search failed', 'error')
					return []
				}
			}).catch(() => {
				addNotification('Search failed', 'error')
			})
	};
}
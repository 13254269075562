
// Libraries
import * as React from 'react'

// Services
import ApiService from "../../../services/api"
import BroadcastsService from "../../../services/broadcasts"

// Components
import PrivateRoute from '../../../components/privateRoute'
import withLocation from "../../../components/withLocation"
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminBroadcastsViewRow from "../../../components/admin/broadcasts/adminBroadcastsViewRow"
import InputFactory from "../../../components/inputFactory"
import BroadcastRecipientManager from "../../../components/broadcastRecipientManager"
import Button from "../../../components/button"
import DuoSection from "../../../components/duoSection"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBroadcastsNewPage extends React.Component {
	state = {
		initialValues: {},
		inputValues: {
			recipients: []
		},
		inputErrors: {},
		templateOptions: [],
		showTinymce: true,
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const {initialValues, inputValues, inputErrors, templateOptions, showTinymce, isSubmitting} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav>
				<Seo title="New Broadcast" />
				<DuoSection adminForm>
					<div>
						<h1>New Broadcast</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form admin-form--broadcast">
							<div className="admin-form__form">
								<form onSubmit={this.handleSubmit}>
									<div className="admin-form__field-group">
										<h2>Broadcast</h2>
										<AdminBroadcastsViewRow
											label="Name"
											value={<InputFactory
												type="text"
												name="name"
												value={inputValues?.name}
												error={inputErrors?.name}
												onChange={this.handleInputChange}
											/>} />
									</div>
									<div className="admin-form__field-group">
										<h2>Content</h2>
										<AdminBroadcastsViewRow
											label="Template"
											value={<InputFactory
												type="select"
												name="template"
												value={inputValues?.template}
												error={inputErrors?.template}
												onChange={this.handleInputChange}
												options={templateOptions}
											/>} />
										<AdminBroadcastsViewRow
											label="Subject"
											value={<InputFactory
												type="text"
												name="subject"
												value={inputValues?.subject}
												error={inputErrors?.subject}
												onChange={this.handleInputChange}
											/>} />
										{showTinymce && <AdminBroadcastsViewRow
											label="Content Editor"
											value={<InputFactory
												type="tinymce"
												name="content"
												initialValue={initialValues?.content}
												value={inputValues?.content}
												error={inputErrors?.content}
												onChange={this.handleInputChange}
											/>} />}
										{!showTinymce && <AdminBroadcastsViewRow
											label="Custom HTML"
											value={<InputFactory
												type="textarea"
												name="content"
												value={inputValues?.content}
												error={inputErrors?.content}
												onChange={this.handleInputChange}
											/>} />}
									</div>
									<div className="admin-form__field-group">
										<h2>Recipients</h2>
										<AdminBroadcastsViewRow
											label="Select Recipients"
											value={<BroadcastRecipientManager
												values={inputValues?.recipients}
												error={inputErrors?.recipients}
												inputChange={this.handleInputChange}
												inputAdd={this.handleBroadcastRecipientAdd}
												inputRemove={this.handleBroadcastRecipientRemove}
											/>} />
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/broadcasts" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={isSubmitting} colorEndeavour>Add Broadcast</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		this.fetchTemplateOptions()
		this.handleNewWithUserRecipient()

		const {initialValues, inputValues} = this.state

		initialValues.content = ''

		inputValues.template = {
			label: 'Generic',
			value: 'generic'
		}
		initialValues.content = ''
	}

	async fetchTemplateOptions() {
		const broadcastsService = new BroadcastsService()

		const templatesResponse = await broadcastsService.getTemplateOptions()
		let templateOptions = []
		templatesResponse.data.forEach((template) =>  {
			if (template.name !== 'test1') {
				templateOptions.push({
					label: template.name.charAt(0).toUpperCase() + template.name.slice(1),
					value: template.name,
				})
			}
		})
		templateOptions.push({
			label: 'None (Custom HTML)',
			value: null,
		})
		this.setState({
			templateOptions: templateOptions
		})
	}

	async fetchRecipientOptions() {
		const broadcastsService = new BroadcastsService()

		const recipientsResponse = await broadcastsService.getRecipientOptions()

		this.setState({
			recipientOptions: recipientsResponse.data
		})
	}

	handleNewWithUserRecipient = () => {
		const {search} = this.props
		if (search.user_id && search.email) {
			const {inputValues} = this.state
			inputValues.recipients.push({
				type: 'user',
				label: 'User: ' + search.email,
				value: search.user_id
			})
		}
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) { // Update field value
			if (name === 'template') {
				this.setState({ showTinymce: value.value !== null })
			}

			if (scope === 'recipients') {
				const prevValues = inputValues[scope][name];
				inputValues[scope][name] = {...value, ...{
					type: prevValues.type
				}}
			}
			else if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else { // Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleBroadcastRecipientAdd = (type) => {
		// Get current form data from state
		const {inputValues} = this.state

		// add blank recipient value with correct type
		inputValues.recipients.push({
			type: type,
			value: ''
		})

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleBroadcastRecipientRemove = (key) => {
		// Get current form data from state
		const {inputValues} = this.state

		// remove recipient value from state
		inputValues.recipients.splice(key, 1)

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {inputValues} = this.state
		const apiService = new ApiService()
		const {addNotification} = this.context

		// prepare form data
		if (inputValues['template']) { // deal with react-select object values
			inputValues['template'] = inputValues['template']['value']
		}
		const formData = JSON.stringify(inputValues)

		// submit event data
		await apiService.post(`broadcasts`, {body: formData})
			.then(async (response) => {
				if (response.success) {
					addNotification('New Broadcast Created', 'success')
					this.props.navigate('/admin/broadcasts')
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default withLocation(AdminBroadcastsNewPage)
